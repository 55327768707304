export function changeStyle(node, styles) {
  Object.keys(styles).forEach((key) => {
    node.style[key] = styles[key];
  });
}

export function ensureTrailingSlashUrl(url) {
  if (!url.endsWith('/')) {
    url += '/';
  }
  return url;
}

export const getWebappBaseUrl = (apiBaseUrl) => {
  if (apiBaseUrl.includes('-api')) {
    return apiBaseUrl.split('-api').join('');
  }
  return apiBaseUrl.split('api.').join('');
};

export const extractDomainFromUrl = () => {
  const { hostname } = window.location;
  const parts = hostname.split('.');
  return parts.slice(-2).join('.');
};

export const isChileanClient = (locale) => {
  if (locale === 'es_cl') {
    return true;
  }

  return false;
};

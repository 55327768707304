import apiService from '../../../../utils/getNominusApi';

// ----------------------------------------------------------------------

export default async function getInspectorColaboratorsOptimized(queryParams, abortController = null) {
  const response = await apiService.post('/api/inspectors/organizations/collaborators/optimized', queryParams, {
    signal: abortController ? abortController.signal : undefined,
  });

  return response.data.data;
}

export const DEFAULT_PERIOD_OPTIONS = [
  { value: 1, label: 'Periodo fijo' },
  { value: 2, label: 'Intervalo' },
  { value: 3, label: 'Fecha única' },
  { value: 4, label: 'Por semana' },
];

export const FORMAT_DATE_REQUEST = 'yyyy-MM-dd';
export const FORMAT_DATE_VIEW = 'dd/MM/yyyy';

export const TYPE_INCIDENTS = {
  withPunch: 1,
  withoutPunchEntry: 2,
  withoutPunchExit: 3,
  withoutPunch: 4,
};

export const PUNCH_EDIT_MOTIVE = [
  { value: 1, label: 'Problemas con huella'},
  { value: 2, label: 'Olvido de Marcaje'},
  { value: 3, label: 'Falla Reloj'},
  { value: 4, label: 'Otro'},
  { value: 5, label: 'Correccion24'},
];

export const tipoAusencia = {
  vacacion: 1,
  licencia: 2,
  cambioTurno: 3,
  cambioHora: 4,
  administrativo: 5,
  permiso: 6,
  inasistencia: 7,
  aviso: 8,
  interestamento: 9,
  cargoVacante: 10,
  permisoPorUnTurno: 11,
  permisoSindical: 12,
  permisoSindicalPorUnTurno: 13,
  capacitacion: 14,
  capacitacionPorUnTurno: 15,
  vacacionInvierno: 16,
  ausenciaDTS: 17,
  ausenciaTurnoExtra: 18,
  ausenciaDT: 19,
  administrativoPorUnTurno: 20,
  permisoFuero: 21,
  interestamentoTurnoExtra: 22,
  orientacion: 23,
  onBoarding: 24,
  orientacionPorUnTurno: 25,
  onBoardingPorUnTurno: 26,
  permisoFallecimiento: 27,
  fla: 28,
  interUnidad: 29,
  permisoCumpleaños: 30,
};

export const detTurno = {
  normal: 1,
  reemplazoCambioTurno: 2,
  reemplazoCambioHora: 3,
  turnoExtra: 4,
  partTime: 5,
  orientacion: 6,
  partTimeOrientacion: 7,
  inasistenciaTurnoExtra: 8,
  inasistenciaDT: 9,
  inasistenciaDTS: 10,
  interestamentoTurnoExtra: 11,
  honorario: 12,
};

export const maxAtrasoPuntualidad = 10;
export const maxAtrasoPuntualidadAcum = 20;

export const UNIT_TYPES = {
  unidadInterna: 1,
  partTime: 2,
  serviciosTransitorios: 3,
  desvinculados: 4,
};
export const TYPE_STATUS_UNIT = {
  unidadImplementada: 1,
  unidadPagando: 2,
};

export const tipoHora = {
  turno_extra: 'TRN_EXTRAS',
  horas_extra: 'HRS_EXTRAS',
};

export const puestosPnetEnHoraExtra = [
  80771, // enf universitaria
  81241, // matrona
  81681, // tecnologo
  81421, // QUIMICO FARMACEUTICO
  80741, // COORDINADORA
  80761, // ENFERMERA_COORD
];

export const tipoSaldoHora = {
  saldoPositivoPorPA: 1,
  saldoPositivoTurno11Horas: 2,
  saldoNegativoTurno13Horas: 3,
};

export const motivoPnet = {
  horasCardiacas: 23,
};

export const tipoIncidencia = {
  conMarcas: 1,
  sinMarcaEntrada: 2,
  sinMarcaSalida: 3,
  sinMarcas: 4,
};

export const tipoTurno = {
  libre: 1,

  // Largos
  largo: 2,
  largoEspecialPabellon: 17,
  largo09002000: 27,
  largo09302130: 53,
  largo08002000SR: 209,

  // Dias
  dia: 4,
  dGrande: 20,
  dia10002200: 21,
  dia12002300: 22,
  dia921: 25,
  rrhh: 47,
  dia10002000: 54,

  // Mañanas
  mañana: 7,
  intermedio: 8,
  mañanaRecuperacion: 10,
  mañanaRecuperacionSabado: 12,
  mañanaAmbulatorio: 15,
  mañana814: 24,
  tarde1Est: 30,
  mañana1Est: 31,
  mañana4Est: 35,
  mañana1Pab: 37,
  mañana2Pab: 38,
  mañana3Pab: 40,
  mañana4Pab: 42,
  mañana5Pab: 43,
  mañana6Pab: 45,
  mañana7Pab: 48,
  mañana8Pab: 49,
  mañana9Pab: 50,
  mañana10Pab: 52,
  mañana07301515: 57,
  mañana08001415: 58,
  mañana08001700: 60,

  // Tardes
  tarde: 9,
  tardeRecuperacion: 11,
  tardeRecuperacionSabado: 13,
  tardeTensRecuperacion: 16,
  tardeEspecialPabellon: 18,
  dChica: 19,
  tarde1120: 29,
  mañana2Est: 32,
  tarde2Est: 33,
  mañana3Est: 34,
  tarde1Pab: 36,
  tarde2Pab: 39,
  tarde3Pab: 41,
  tarde4Pab: 44,
  tarde5Pab: 46,
  tardeUrg: 51,
  tarde13002000: 55,
  tarde13452130: 56,

  // Noches
  noche: 3,
  nChica: 23,
  noche21000800: 28,
  nocheFijo08002000: 141,

  // Intemedio
  intermedioAmbulatorio: 14,

  // Refuerzo
  refuerzo: 26,
};

export const FormatosReportes = {
  // En este nivel se encuentran los formatos estándar para la aplicación.
  default: {
    marcas: 'yyyy-MM-dd HH:mm:ss',
    horas: 'HH:mm',
    duraciones: 'HH:mm',
    entregaTurnoDecimal: 'H,',
    duracionesMinutos: 'm',
    duracionesDecimales: 'HH,',
  },
  clinicas: {
    // En este nivel se encuentran los formatos estándar para cada clínica.
    // Si un formato no existe en este nivel, se utilizará el predeterminado de la aplicación.
    davila: {
      default: {
        duraciones: 'HH,',
      },
    },
    stamaria: {
      reportePartTime: {
        duraciones: 'H',
        duracionesMinutos: 'm',
        duracionesDecimales: 'H!',
      },
    },
    // En este nivel se encuentran los formatos para cada reporte en donde exista una excepción al formato estándar de cada clínica o el de la aplicación.
    // Agregar excepciones al formato normal de la clínica con el siguiente formato:
    // nombreReporte: {
    //  formatos...
    // }
  },
};

export const rol = {
  admin: 1,
  gerencia: 2,
  pabellon: 3,
  trabajador: 4,
  pabellonSeleccion: 5,
  superAdmin: 6,
  adminroot: 7,
  adminTarea: 10,
  mostrarVistaDotacionActividad: 14,
  soloTensRecuperacion: 15,
  gerenciaContrato: 16,
  soloAuxRayos: 17,
  gerenciaGeneral: 18,
  inmunidadCierrePeriodo: 19,
  gerenciaMantenedor: 20,
  gerenciaControladorBonificaciones: 21,
  gerenciaFuero: 22,
  adminRflex: 460,
  atencionCliente: 461,
  gerenciaMotivos: 462,
  direccionTrabajo: 463,
  configuradorLlamados: 464,
  vistaGerenciaEnfermeria: 465,
  crearAdminRRHHCommander: 467,
  vistaLecturaPlanillasRRHH: 468,
  adminOcupacionEnfermeria: 469,
  adminPresupuestoHorasExtras: 470,
  monitorDeReloj: 471,
  adminAsignaCentroCostoOtraUnidad: 472,
  adminValidadorTurno: 473,
  jefaturaVerReporteDotacionProyectada: 474,
  validadorTicketCasino: 475,
  adminSitioCasino: 476,
  gerenciaLimitadaPorUnidad: 480,
  gerenciaModuloBeneficios: 481,
  validadorTicketBeneficio: 482,
};

export const estamento = {
  enfermeras: 1,
  tens: 2,
  aux: 3,
  secretarias: 4,
  matronas: 5,
  auxDistribucion: 6,
  instrumental: 7,
  auxRayos: 9,
  medicos: 10,
  estafeta: 11,
  enfermera_supervisor: 15,
};

export const unidades = {
  pabellon: 5,
  urgencia: 6,
  urgenciaPediatrica: 9,
  upcp: 13,
  partTime: 1,
};

export const TIPO_PERIODO = {
  cierrePeriodo: 1,
  periodoPago: 2,
  cierrePeriodoPartTime: 3,
  periodoPagoPartTime: 4,
};

export const TYPE_CONTRACTS = {
  contratado: 1,
  partTime: 2,
  serviciosTransitorios: 3,
  desvinculado: 4,
};

export const COMPANY_TYPES = {
  INTERNAL: 1,
  EXTERNAL: 2,
  EST: 3,
};

export const SNAPSHOT_CHANGE_TYPES = {
  acl: {
    // Creación de rol
    roleCreate: 'role.create',
    roleCreatePrivilegeSetup: 'role.create.privilege.setup',
    // Actualización de rol
    roleUpdate: 'role.update',
    // Eliminación de rol
    roleDelete: 'role.delete',
    roleDeleteUserUnassign: 'role.delete.user.unassign',
    // Asignación y desasignación de rol
    roleUserAssign: 'role.user.assign',
    roleUserUnassign: 'role.user.unassign',
    // Manejo de privilegios de rol
    rolePrivilegeUpdate: 'role.privilege.update',
  }
}

export const TYPE_OF_USERS_PERMISSIONS = [
  {
    code: 'gdp',
    name: 'Perfil de recursos humanos',
    description: 'Perfil de gestión de personas, remuneraciones y recursos humanos.'
  },
  {
    code: 'gdt',
    name: 'Perfil de coordinador de turnos',
    description: 'Perfil de gestión de turno, jefaturas de servicios o unidades y coordinadores.'
  },
  {
    code: 'gdr',
    name: 'Gestión de relojes',
    description: 'Perfil asociado al monitoreo de relojes.'
  }
];

export const FLEXIBLE_SCHEDULES_DIRECTIONS = {
  afterSchedule : {
    value: 1,
    label: 'Ajuste por atraso'
  },
  beforeSchedule : {
    value: 2,
    label: 'Ajuste por entrada anticipada'
  },
  mixed: {
    value: 3,
    label: 'Ajuste en ambas direcciones'
  }
}


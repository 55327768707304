import apiService from '../../../../utils/getNominusApi';

// ----------------------------------------------------------------------

export default async function getInspectorWorkSchedules(filters) {
  const response = await apiService.post('/api/inspectors/organizations/work-schedules', filters);

  const { data } = response.data;

  // TODO: Refactorizar respuesta de nominus para cuando sea un error desde presenza, responda con un error.
  if (!Array.isArray(data)) {
    return [];
  }

  return data;
}
